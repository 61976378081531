
// .Registry {
//     margin:  0 auto;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }

// .registryText {
//     // display: flex;
//     // flex-direction:column;
//     // flex-wrap: wrap;
//     width: 756px;
//     background: red;
//     margin: 0 auto;
//     padding: 20px 50px 36px 50px;
//     font-size: 16px;
//     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//     font-weight: 400;
//     color: #404040;
//     line-height: 36px;
//     text-align: justify;
// }

.Registry {
    .info {
  margin-bottom: 20px;
  margin-top: 24px;
  .infomain {
    margin: 20px 0;
    ul {
        text-align: justify;
    }
    li {
        line-height: 24px;
    }
    .info-title {
        margin-bottom: 24px;
        text-align: center;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #101010;
          line-height: 24px;
        }
      }
    .standard {
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }

    }

  }
}

}

